import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const Caption = makeShortcode("Caption");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`Thanks for completing our tutorial and helping us improve it along the way. If you want to show the world your new skills, you can apply for an IBM Digital Badge.`}</p>
    </PageDescription>
    <AnchorLinks mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">Application form</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Badge information</AnchorLink>
    </AnchorLinks>
    <h2 {...{
      "id": "application-form"
    }}>{`Application form`}</h2>
    <p>{`Once you complete all five steps of the tutorial, you can apply for an `}<a parentName="p" {...{
        "href": "https://www.surveygizmo.com/s3/5215482/IBM-Carbon-Design-System-Developer-Essentials-Vue-v2"
      }}>{`IBM Digital Badge`}</a>{`. These badges are available to anybody — not just IBM employees.`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1120px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "63.035714285714285%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsSAAALEgHS3X78AAACH0lEQVQ4y2NgwAK2325j+P77I5h98skSIPsz0////5kef7zIBFNz9dUOht5j9gwEwc03++HsF59vggxCkQfyma+92sUIYv/795fh2KP5DEQBoEZGGPv1tztxn3+9nHvjzW51mNjjjxcYoepwGwLzAsywyy+3mX788XzPfyj49uvjt1dfbrfOOxcriGwYXq+//HKTGUT//vMjEGbQh7ff/9y68ejX/78wkf+Xr73ayw1S9/brQzwuPG4Ld93mm3UrLn9Y8//k4yU/Tj5e+u/Uk2X/Tzxe8hdI/z75YsH/ueciXUHqFl5IZHr0PxSHgUecwYY17TUVnHLS5/3Ms0H/55+P+bfwQsK/2WfD/807H/N/zrnIn3Ou+/yfeNy9A6R2yilv5o6DdtgNjJ9sCmMyLj3ceeLZt/P/rzzd8/Pmq4P/rr888P/q831/b70+/Ovm24P/c2d7xoIUlsyKY6rebI3b2zceX2IBJ587T6NhAfb0yes/jx6+/PX5ww8w/9OnHzdENKK5QOp65s3Gbdih/34osbz/3B77Z6+fHv/159f/f0D49sObX7cf3+ptXFgmApI/eOIiWF0NLhcq+Koip0M4+9aj29lPXj1dte/Mfn245ecPgw2rnlrHoBightuVW49sh7Ov3r3GjCXBM+88sRts2LPXzxl6FvcRziUz185muHT7MoS9bjYwTf4G5+Uz184ywVx/+uoZhojKaAy9AOXCWeAmmJT7AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "carbon badge vue",
            "title": "carbon badge vue",
            "src": "/static/15699dfe9ddcfed359056b9d5640f87e/026f9/carbon-badge-vue.png",
            "srcSet": ["/static/15699dfe9ddcfed359056b9d5640f87e/d6747/carbon-badge-vue.png 288w", "/static/15699dfe9ddcfed359056b9d5640f87e/09548/carbon-badge-vue.png 576w", "/static/15699dfe9ddcfed359056b9d5640f87e/026f9/carbon-badge-vue.png 1120w"],
            "sizes": "(max-width: 1120px) 100vw, 1120px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <Caption mdxType="Caption">IBM Digital Badge</Caption>
      </Column>
    </Row>
    <p>{`Once you submit the application, please allow two to four days for issuing the badge. Once the badge is issued, you will be notified and must accept the badge via Acclaim.`}</p>
    <h2 {...{
      "id": "badge-information"
    }}>{`Badge information`}</h2>
    <h3 {...{
      "id": "ibm-design-system-vue-components"
    }}>{`IBM Design System Vue Components`}</h3>
    <p>{`This badge demonstrates knowledge about Carbon’s Vue components. To earn the badge:`}</p>
    <ol>
      <li parentName="ol">{`Complete steps 1 through 5 of the Carbon Vue tutorial`}<ul parentName="li">
          <li parentName="ul">{`Step 1. `}<a parentName="li" {...{
              "href": "/tutorial/vue/step-1"
            }}>{`Installing Carbon`}</a></li>
          <li parentName="ul">{`Step 2. `}<a parentName="li" {...{
              "href": "/tutorial/vue/step-2"
            }}>{`Building pages`}</a></li>
          <li parentName="ul">{`Step 3. `}<a parentName="li" {...{
              "href": "/tutorial/vue/step-3"
            }}>{`Using APIs`}</a></li>
          <li parentName="ul">{`Step 4. `}<a parentName="li" {...{
              "href": "/tutorial/vue/step-4"
            }}>{`Creating components`}</a></li>
          <li parentName="ul">{`Step 5. `}<a parentName="li" {...{
              "href": "/tutorial/vue/step-5"
            }}>{`Deploying to IBM Cloud`}</a></li>
        </ul></li>
      <li parentName="ol">{`Have approved pull requests for steps 1 through 5 in the `}<a parentName="li" {...{
          "href": "https://github.com/carbon-design-system/carbon-tutorial-vue"
        }}>{`carbon-tutorial-vue repository`}</a></li>
      <li parentName="ol">{`Complete the `}<a parentName="li" {...{
          "href": "https://www.surveygizmo.com/s3/5215482/IBM-Carbon-Design-System-Developer-Essentials-Vue-v2"
        }}>{`badge application`}</a></li>
    </ol>
    <p><em parentName="p">{`Note: To quickly find your submitted PRs, you can `}<a parentName="em" {...{
          "href": "https://github.com/carbon-design-system/carbon-tutorial-vue/pulls?utf8=%E2%9C%93&q=author%3Ausername"
        }}>{`filter by author`}</a>{` (e.g. `}</em><inlineCode parentName="p">{`author:\${username}`}</inlineCode><em parentName="p">{`).`}</em></p>
    <h3 {...{
      "id": "faq-and-help"
    }}>{`FAQ and help`}</h3>
    <p>{`Badge Issuer Contact: kellychurchill@us.ibm.com`}</p>
    <p>{`Acclaim Support: For questions related to your Acclaim badge earner account and profile, as well as issues related to claiming your badge after receiving a notification, go to `}<a parentName="p" {...{
        "href": "http://support.youracclaim.com"
      }}>{`http://support.youracclaim.com`}</a>{` for account issues.`}</p>
    <h3 {...{
      "id": "privacy-statement"
    }}>{`Privacy statement`}</h3>
    <p>{`NOTICE: IBM leverages the services of Credly’s Acclaim platform, a 3rd party data processor authorized by IBM and located in the United States, to assist in the administration of the IBM Digital Badge program. In order to issue you an IBM Digital Badge, your personal information (name, email address, and badge earned) will be shared with the Credly’s Acclaim platform. You will receive an email notification from Acclaim with instructions for claiming the badge. Your personal information is used to issue your badge and for program reporting and operational purposes. It will be handled in a manner consistent with IBM privacy practices. The IBM Privacy Statement can be viewed here: `}<a parentName="p" {...{
        "href": "https://www.ibm.com/privacy/us/en/"
      }}>{`https://www.ibm.com/privacy/us/en/`}</a>{`. IBM employees can view the IBM Internal Privacy Statement here: `}<a parentName="p" {...{
        "href": "https://w3.ibm.com/w3publisher/w3-privacy-notice"
      }}>{`https://w3.ibm.com/w3publisher/w3-privacy-notice`}</a>{`.`}</p>
    <h3 {...{
      "id": "application-form-1"
    }}>{`Application form`}</h3>
    <p>{`To apply for the IBM Digital Badge, please complete the `}<a parentName="p" {...{
        "href": "https://www.surveygizmo.com/s3/5215482/IBM-Carbon-Design-System-Developer-Essentials-Vue-v2"
      }}>{`form and survey`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      